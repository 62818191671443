import { graphql } from 'gatsby'

const IndexPage = () => {
  return null
}

export const query = graphql`
  query IndexPageQuery {
    content: contentful {
      siteSettings(id: "6R1gjaa1GMsWoGOU2GKiU4", locale: "en") {
        ...SiteSettings
      }
    }
    contentful_t {
      navigationCollection(limit: 3, locale: "en") {
        items {
          ...TopNavigation
        }
      }
      footerCollection(limit: 3, locale: "en") {
        items {
          ...FooterNavigation
        }
      }
    }
  }
`

export default IndexPage
